<template>
  <el-container>
    <el-header>
      店铺信息
    </el-header>
    <el-main class="main">

      <div class="content">
        <div class="shop">
          <div class="cell">
            <div class="title">
              店铺LOGO
            </div>
            <div class="value">
              <el-upload class="avatar-uploader" :action="$imgFile"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="$getimgsrc(imageUrl)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
          <div class="cell">
            <div class="title">
              店铺名称
            </div>
            <div class="value" @click="nameShow = true">
              <span>{{nameInput}}</span>
              <span class="el-icon-arrow-right"></span>
            </div>
            <el-dialog title="提示" :visible.sync="nameShow" width="30%" :before-close="diaLogClose">
              <el-input v-model="changeInput" placeholder="请输入新的店铺名称"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="nameShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(1)">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <div class="cell" style="border-bottom: 1px solid #f2f2f2;padding: 0 50px;display: flex;justify-content: space-between;align-items: center;">
            <div class="title" style="height: 25px;line-height: 25px;">
              店铺简介
            </div>
            <div class="value" @click="introductionShow=true">
              <!-- <span v-html="introductionInput"></span> -->
              <span>{{introductionInput}}</span>
              <span class="el-icon-arrow-right"></span>
            </div>
            <el-dialog title="提示" :visible.sync="introductionShow" width="30%" :before-close="diaLogClose">
              <el-input v-model="jianjie" placeholder="请输入新的店铺简介" maxlength="100" show-word-limit></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="introductionShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(2)">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <div class="cell">
            <div class="title">
              店铺电话
            </div>
            <div class="value" @click="telShow=true">
              <span>{{telInput}}</span>
              <span class="el-icon-arrow-right"></span>
            </div>
            <el-dialog title="提示" :visible.sync="telShow" width="30%" :before-close="diaLogClose">
              <el-input v-model="tel" placeholder="请输入新的店铺电话"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="telShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(3)">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <!-- <div class="cell">
            <div class="title">
              店铺地址
            </div>
            <div class="value" @click="addressShow=true">
              <span>{{addressInput}}</span>
              <span class="el-icon-arrow-right"></span>
            </div>
            <el-dialog title="提示" :visible.sync="addressShow" width="30%" :before-close="diaLogClose">
              <el-input v-model="changeInput" placeholder="请输入新的店铺地址"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="addressShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(4)">确 定</el-button>
              </span>
            </el-dialog>
          </div> -->
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        // 店铺信息
        imageUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
        nameInput: '智慧门店',
        changeInput: '',//店铺名称
        jianjie:'',//简介
        tel:'',//电话
        nameShow: false,
        introductionInput: '店铺简介',
        introductionShow: false,
        telInput: '158 5858 8585',
        telShow: false,
        addressInput: '河南省郑州市二七区',
        addressShow: false,
        shopInfo:{}
      }
    },
    mounted(){
      this.getShopInfo()
    },
    methods: {
      //修改信息
      edit(){
        this.$http.post('api/store/storeset/shopinforedit',{
          name:this.changeInput,
          logo:this.imageUrl,
          tel:this.tel,
          content:this.jianjie
        }).then(res =>{
          this.$message.success(res.data.msg)
          this.getShopInfo()
        })
      },
      //获取店铺信息
      getShopInfo(){
        this.$http.post('api/store/storeset/storeinfor').then(res =>{
          this.shopInfo = res.data.data
          this.nameInput = res.data.data.name
          this.changeInput = res.data.data.name
          this.imageUrl = res.data.data.logo
          this.introductionInput = res.data.data.content
          this.jianjie = res.data.data.content
          this.telInput = res.data.data.tel
          this.tel = res.data.data.tel
          this.addressInput =  res.data.data.dizhi+res.data.data.address_detail
        })
      },
      confirmChange(id) {
        this.edit()
        this.nameShow = false
        this.introductionShow = false
        this.telShow = false
        // if (id === 1) {
        //   this.nameInput = this.changeInput
        //   this.changeInput = ''
        //   this.nameShow = false
        // } else if (id === 2) {
        //   this.introductionInput = this.changeInput
        //   this.changeInput = ''
        //   this.introductionShow = false
        // } else if (id === 3) {
        //   this.telInput = this.changeInput
        //   this.changeInput = ''
        //   this.telShow = false
        // } else if (id === 4) {
        //   this.addressInput = this.changeInput
        //   this.changeInput = ''
        //   this.addressShow = false
        // }
      },
      // 关闭DiaLog弹窗
      diaLogClose(done) {
        done();
      },
      // 上传头像
      handleAvatarSuccess(res, file) {
        this.imageUrl = res.data.url
        this.edit()

      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
    }
  }

</script>

<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
  }

  /deep/ .el-main {
    padding: 20px !important;
    background-color: #f9f9f9;

    .content {
      padding: 20px 20px 0;
      border-radius: 10px;
      box-shadow: 0 0 2px #ccc;
      background-color: #fff;

      .shop {
        background-color: #fff;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 50px;
          height: 50px;
          border-bottom: 1px solid #f2f2f2;
          color: #666;

          &:first-child {
            height: auto;
            padding: 20px 50px;
          }

          &:last-child {
            border-bottom: none;
          }

          .value {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 260px;


            .el-icon-arrow-right {
              margin-left: 20px;
              font-size: 16px;
              font-weight: bold;
            }

            // /deep/ .el-input {
            // .el-input__inner {
            // text-align: right;
            // border: none;
            // padding: 0;
            // min-width: 300px;
            // }
            // }
          }
        }
      }
    }
  }

  // 上传头像
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border: 1px solid #8c939d;
    border-radius: 100%;
  }

  .avatar {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 100%;
  }

  /deep/ .el-dialog {
    min-width: 360px;
  }

</style>
